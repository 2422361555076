import React, { useContext } from "react";
import { colors, createTheme } from "@mui/material";
import { AuthenticationContext } from "../context/AuthenticationContext";
import { AppThemeContext } from "../context/AppThemeContext";
const MainSpacing = 4

const GetTheme = () => {
    const { PrimaryColor } = useContext(AppThemeContext)
    const theme = createTheme({
        spacing: MainSpacing,
        palette: {
            mode: 'light',
            background: {
                default: "hsl(0, 0%, 100%)",
            },
            primary: {
                main: PrimaryColor
            },
            secondary: {
                main: colors.lime[500]
            },
            info: {
                main: colors.purple[500]
            },
            error: {
                main: colors.red[500]
            },
            blue: '#003399',
            tintBlue: '#326EE6'
        },
        shape: {
            borderRadius: 5
        },
        components: {
            MuiSwitch: {
                // styleOverrides: {
                //     switchBase: {
                //         color: "#E60060",
                //         "&.Mui-checked": {
                //             color: "green"
                //         }
                //     },
                //     track: {
                //         backgroundColor: "green"
                //     }
                // },
            },
            MuiCard: {
                defaultProps: {
                    // disableElevation: false
                },
                styleOverrides: {
                    root: {
                        // borderRadius: 8
                    }
                }
            },
            // MuiCardContent: {
            //     styleOverrides: {
            //         root: {
            //             padding: 10
            //         }
            //     }
            // },
            MuiCardActions: {
                defaultProps: {
                    // disableSpacing: true
                },
                styleOverrides: {
                    root: {
                        padding: MainSpacing
                    }
                }
            },
            MuiCardHeader: {
                defaultProps: {
                    // disableSpacing: true

                },
                styleOverrides: {
                    root: {
                        padding: MainSpacing
                    }
                }
            },
            MuiListItemButton: {
                defaultProps: {
                    dense: true,
                    disableGutters: false,
                },
                styleOverrides: {
                    root: {
                        // padding: 0,
                        borderTopRightRadius: 10,
                        borderBottomRightRadius: 10,
                        marginRight: 10,
                        // paddingLeft: 5,
                        marginBottom: 5
                    }
                }
            },
            MuiDrawer: {
                styleOverrides: {
                    paper: {
                        border: 0
                    }
                }
            },
            MuiButton: {
                defaultProps: {
                    disableElevation: true,
                    disableRipple: true
                },
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                        // borderRadius: 0,
                        // "&:hover": {
                        //     backgroundColor: 'yellow'
                        // }
                    }
                }
            },
            MuiPaper: {
                defaultProps: {
                    // square: true,
                    // elevation: 5,
                    variant: 'outlined'
                },
                styleOverrides: {
                    root: {
                        // borderRadius: 8
                    }
                }
            }
        },
        typography: {
            fontSize: 12,
            htmlFontSize: 10,
            h1: {
                fontWeight: 300,
                fontSize: "6rem",
                lineHeight: 1.167,
                letterSpacing: "-0.01562em"
            },
            h2: {
                fontWeight: 300,
                fontSize: "3.75rem",
                lineHeight: 1.2,
                letterSpacing: "-0.00833em"
            },
            h3: {
                fontWeight: 400,
                fontSize: "3rem",
                lineHeight: 1.167,
                letterSpacing: "0em"
            },
            h4: {
                fontWeight: 400,
                fontSize: "1.650rem",
                lineHeight: 1.235,
                letterSpacing: "0.00735em"
            },
            h5: {
                fontWeight: 400,
                fontSize: "1.2rem",
                lineHeight: 1.334,
                letterSpacing: "0em"
            },
            h6: {
                fontWeight: 500,
                fontSize: "1.25rem",
                lineHeight: 1.6,
                letterSpacing: "0.0075em"
            },
            body1: {
                // fontFamily: "'Inter', sans- serif",
                fontWeight: '700',
                fontSize: "0.600rem",
                lineHeight: 1.5,
                letterSpacing: "0.00938em"
            },
            body2: {
                fontFamily: "'Inter', sans- serif",
                fontWeight: 400,
                fontSize: "0.875rem",
                lineHeight: 1.43,
                letterSpacing: "0.01071em"
            },
            subtitle1: {
                fontWeight: 400,
                fontSize: "1rem",
                lineHeight: 1.75,
                letterSpacing: "0.00938em"
            },
            subtitle2: {
                fontWeight: 500,
                fontSize: "0.875rem",
                lineHeight: 1.57,
                letterSpacing: "0.00714em"
            },
            button: {
                fontWeight: 500,
                fontSize: "0.875rem",
                lineHeight: 1.75,
                letterSpacing: "0.02857em",
                textTransform: "uppercase",
            },
            caption: {
                fontWeight: 400,
                fontSize: "0.75rem",
                lineHeight: 1.66,
                letterSpacing: "0.03333em"

            },
            overline: {
                fontWeight: 400,
                fontSize: "0.75rem",
                lineHeight: 2.66,
                letterSpacing: "0.08333em",
                textTransform: "uppercase"
            }
        },
        mixins: {
            toolbar: {
                minHeight: 50
            }
        }
        // ... (rest of your theme configuration)
    });

    theme.typography.h6 = {
        fontWeight: 'bold',
        [theme.breakpoints.up('xl')]: {
            fontSize: '1.9rem'
        },
        [theme.breakpoints.down('xl')]: {
            fontSize: '1.2rem'
        }
    }
    theme.typography.h4 = {
        fontWeight: '800',
        [theme.breakpoints.up('xl')]: {
            fontSize: '1.7rem',
            fontWeight: "800"
        },
        [theme.breakpoints.down('xl')]: {
            fontSize: '1rem',
            fontWeight: "800"
        }
    }
    theme.typography.h5 = {
        // fontWeight: '800',
        [theme.breakpoints.up('xl')]: {
            fontSize: '1.1rem'
        },
        [theme.breakpoints.down('xl')]: {
            fontSize: '0.800rem',
            // fontWeight: "800"
        }
    }
    theme.typography.body1 = {
        fontSize: "0.800rem",
        fontWeight: '800',
        [theme.breakpoints.up('xl')]: {
            fontSize: '0.700rem'
        },
        [theme.breakpoints.down('xl')]: {
            fontSize: '0.600rem'
        }
    }
    theme.typography.body2 = {
        fontSize: '0.900rem',
        [theme.breakpoints.up('xl')]: {
            fontSize: '0.900rem',
        },
        [theme.breakpoints.down('xl')]: {
            fontSize: '0.700rem',
        },
    }

    const themeVariables = {
        '--primary-color': theme.palette.primary.main,
        '--background-color': theme.palette.background.default,
        // Add more variables as needed
    };

    return { theme, themeVariables };
};
export default GetTheme;
