import React, { createContext, useEffect, useState } from 'react'
import AxiosApi from '../api/AxiosApi';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export const AuthenticationContext = createContext()
export function AuthenticationProvider({ children }) {
    const Navigate = useNavigate()
    const [IsLogged, setIsLogged] = useState(false)
    const [LoginLoading, setLoginLoading] = useState(false)
    const [LoggedInUserInfo, setLoggedInUserInfo] = useState(null)
    // LOGIN_NAME is LOGIN_ID
    function userLogin(LOGIN_NAME, PW, APP_KEY) {
        localStorage.setItem('LoginID', LOGIN_NAME)
        setLoginLoading(true)
        let headersList = {
            "Accept": "/",
            "Content-Type": "application/x-www-form-urlencoded"
        }
        let bodyContent = `LOGIN_NAME=${LOGIN_NAME}&PW=${PW}&APP_KEY=${APP_KEY}`;
        AxiosApi.post('login/LoginWEB', bodyContent, headersList)
            .then((res) => {
                localStorage.setItem('LoggedInUserInfo', JSON.stringify(res?.data))
                localStorage.setItem('LCODE', res?.data?.Loc_Code)
                setLoggedInUserInfo(res?.data)
                setLoginLoading(false)
                setIsLogged(true)

            }).catch((er) => {
                alert('Invalid Credentials!')
                console.log('err', er)
                setLoginLoading(false)
            });

    }

    function logOut() {
        localStorage.clear()
        setLoggedInUserInfo(null)
        setIsLogged(false)
        // navigate('./')
    }

    useEffect(() => {
        const data = localStorage.getItem('LoggedInUserInfo')
        const userData = JSON.parse(data)
        if (userData) {
            setIsLogged(true)
            setLoggedInUserInfo(userData)
            Navigate('/')
        } else {
            setIsLogged(false)
            setLoggedInUserInfo(null)
            localStorage.clear()
            Navigate('login')
        }
    }, [IsLogged])




    return (
        <AuthenticationContext.Provider value={{ IsLogged, setLoggedInUserInfo, LoggedInUserInfo, LoginLoading, userLogin, logOut }}>
            {children}
        </AuthenticationContext.Provider>
    )
}
